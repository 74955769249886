const env = window as any;
export class Config {
  // Dashboard routes
  static loginRoute = "/login";
  static dashboardRoute = "/dashboard";
  static loginDeviceCodeRoute = "/code";

  // API config
  static clientName = env.CLIENT_NAME;
  static environment = env.ENVIRONMENT;
  static versionTag = env.VERSION_TAG;
  static envUrlPrefix = env.ENV_URL_PREFIX;
  static apiHostname = env.API_HOST_NAME;
  static clientUrlSuffix = env.CLIENT_URL_SUFFIX;
  static baseV2Host = `${Config.envUrlPrefix}${Config.apiHostname}${Config.clientUrlSuffix}/v2`;
  static dashboardOrigin =
    window.location.protocol + "//" + window.location.hostname;

  // OpenID Connect config
  static oidcAuthDomain = "login.kanda.dk";
  static oidcClientId = env.OIDC_CLIENT_ID;
  static oidcScopes = ["openid", "profile", "email", "offline_access"];
  static oidcDeviceCodeInputUrl = env.OIDC_DEVICE_CODE_INPUT_URL;

  // TODO: Deprecate this once Auth0 is fully rolled out
  static oidcLegacyDeviceCodeInputUrl = "https://microsoft.com/devicelogin";
}
