import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Config } from "../config";
import { IErrorResponse, ICheckInResponse, IQuickCodeCreateResponse, IQuickCodeCheckResponse, IQuickCodeConsumeResponse } from "./apiModels"

/**
 * Class for handling API calls from dashboard
 */
class NetworkManager {
    private static instance: NetworkManager;
    public static getInstance(): NetworkManager {
        if (!NetworkManager.instance) {
            NetworkManager.instance = new NetworkManager();
        }
        return NetworkManager.instance;
    }

    private api: AxiosInstance;

    private constructor() {
        const host = Config.baseV2Host;
        const protocol = "https";
        this.api = axios.create({
            baseURL: `${protocol}://${host}`,
            responseType: "json",
            withCredentials: true,
            headers: {
                'Access-Control-Allow-Headers': 'content-type, accept, origin',
                'Access-Control-Allow-Origin': Config.dashboardOrigin,
                'Access-Control-Allow-Methods': 'POST, GET, PUT, DELETE',
                'Access-Control-Allow-Credentials': true
            }
        });
    }

    public async CheckIn(token: string): Promise<AxiosResponse<ICheckInResponse | IErrorResponse>> {
        const options: AxiosRequestConfig = {
            method: "POST",
            url: "/checkins",
            data: { origin: "dashboard" },
            headers: this.CreateAuthHeader(token)
        };
        const res = await this.BaseRequest(options);
        return res as AxiosResponse<ICheckInResponse | IErrorResponse>;
    }

    private async BaseRequest<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T | IErrorResponse>> {
        const res = await this.api
            .request<T>(config)
            .catch(error => {
                return error.response as AxiosResponse<IErrorResponse>;
            });

        return res as AxiosResponse<T>;
    }

    private CreateAuthHeader(token: string): Record<string, string> {
        return { "Authorization": `Bearer ${token}` };
    }
}

export default NetworkManager;
