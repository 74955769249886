import { Col, Container, Row } from "react-bootstrap";
import ErrorAlert, {
  AlertInterface,
} from "../components/responsehandling/errorAlert";
import React, { Component } from "react";

import CardWidget from "../components/cardWidget";
import NetworkManager from "../lib/networkManager";
import { RouteComponentProps } from "react-router-dom";
import constructionImg from "../assets/img/dashboard/construction_iso.png";
import AuthUtilities from "../lib/authUtilities";
import {
  IErrorResponse,
  ICheckInResponse,
  IQuickCodeCreateResponse,
  IQuickCodeConsumeResponse,
} from "../lib/apiModels";
import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";

class Dashboard extends Component<WithAuth0Props> {
  networkManager: NetworkManager = NetworkManager.getInstance();
  timer: NodeJS.Timeout | undefined;
  checkCodeValidity: NodeJS.Timeout | undefined;

  state = {
    username: "",
    alert: { show: false, message: "", status: "" } as AlertInterface,
  };

  componentDidMount() {
    this.handleLogin();
  }

  render() {
    return (
      <Container>
        <Row
          md={8}
          xs={12}
          noGutters={true}
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "15vh",
          }}
        >
          <Col
            md={6}
            xs={12}
            style={{
              marginBottom: "30px",
              marginTop: "0px",
            }}
          >
            <h1
              style={{
                color: "#ffffff",
                fontFamily: "Nats-Regular",
                fontSize: "60px",
              }}
            >
              Welcome, {this.state.username}
            </h1>
            <h6 style={{ color: "#ffffff" }}>
              The Bridge Dashboard is currently under construction.
            </h6>
            <Row style={{ justifyContent: "center" }}>
              <img src={constructionImg} alt="Construction" />
            </Row>
          </Col>
          <Col md={6} xs={12}>
            <Row
              style={{
                justifyContent: "center",
              }}
            >
              <CardWidget
                onClick={() => {
                  AuthUtilities.StartDeviceCodeInput();
                }}
                cardTitle="Quick code"
                cardDescription="Open Bridge on your device and generate a quick code. Input the code using the link below"
                cardNotes="If your code does not work, you might be using an old version of the app. Try entering your code at: microsoft.com/devicelogin"
                buttonText="Input code"
              />
            </Row>
          </Col>
        </Row>
        <ErrorAlert
          show={this.state.alert.show}
          onUpdateShow={() => this.resetAlert()}
          header={this.state.alert.message}
          message={this.state.alert.status}
        />
      </Container>
    );
  }

  handleLogin() {
    const { getAccessTokenSilently, getIdTokenClaims } = this.props.auth0;

    getAccessTokenSilently().then((token) => {
      getIdTokenClaims().then((idToken) => {
        this.setState({ username: AuthUtilities.GetFirstName(idToken) });
      });
    });

    // Try to check in the dashboard and keep checking in every 5 minutes
    this.tryCheckIn();
    setInterval(() => this.tryCheckIn(), 300000);
  }

  tryCheckIn = async () => {
    const { getAccessTokenSilently, getIdTokenClaims } = this.props.auth0;

    try {
      const token = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();

      if (idToken == undefined) {
        return;
      }

      // Try checking in and make alert if API not available
      this.networkManager.CheckIn(idToken.__raw).then((checkInRes) => {
        if (checkInRes.status != 200) {
          const errorInfo = checkInRes.data as IErrorResponse;
          this.setState({
            alert: {
              show: true,
              message:
                "Sorry, we cannot reach the backend service. Try again later or contact a Bridge admin.",
              status:
                "Error code: " +
                (errorInfo?.bridgeErrorCode ?? "bridge-001-servererror"),
            },
          });
        }
      });
    } catch (error) {
      console.error(
        "There was a problem logging in user: " + JSON.stringify(error)
      );
      window.location.href = "/login";
    }
  };

  resetAlert = () => {
    this.setState({
      alert: {
        show: false,
        message: "",
        status: "",
      },
    });
  };
}

export default withAuth0(Dashboard);
