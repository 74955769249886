import { Container, Navbar } from "react-bootstrap";
import { Link, Route } from "react-router-dom";
import React, { Component } from "react";

import Col from "react-bootstrap/Col";
import NetworkManager from "../lib/networkManager";
import ProfileArea from "./Navbar/profileArea-default";
import ProfileAreaMobile from "./Navbar/profileArea-mobile";
import Row from "react-bootstrap/Row";
import { isMobile } from "react-device-detect";
import logo from "../assets/bridge_logo.svg";
import AuthUtilities from "../lib/authUtilities";
import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";

class Header extends Component<WithAuth0Props> {
  networkManager: NetworkManager = NetworkManager.getInstance();

  headerTitle = "20px";

  state = {
    username: "",
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims } =
      this.props.auth0;

    getAccessTokenSilently()
      .then((token) => {
        getIdTokenClaims().then((idToken) => {
          this.setState({
            username: AuthUtilities.GetFirstName(idToken),
          });
        });
      })
      .catch(() => {
        console.log("TODO: Handle user name in header propely!");
      });
  }

  handleLogout = async () => {
    const { logout } = this.props.auth0;

    AuthUtilities.ClearBridgeCredentials();
    logout({ returnTo: window.location.origin });
  };

  render() {
    return (
      <Navbar
        bg="dark"
        variant="dark"
        fixed="top"
        style={{ backgroundColor: "#292929" }}
        className="d-inline-block align-top"
      >
        <Container>
          <Navbar.Brand href="#" style={{ flex: 1 }}>
            <Row style={{ alignItems: "center", flex: 1 }} noGutters>
              <Col xs={4}>
                <Link to="/dashboard" style={{ flex: 1 }}>
                  <Row style={{ alignItems: "center" }} noGutters={true}>
                    <img
                      src={logo}
                      alt={""}
                      width="60"
                      height="60"
                      style={{ margin: "0px 15px" }}
                    />
                    {isMobile ? undefined : (
                      <h1
                        style={{
                          fontSize: this.headerTitle,
                          fontFamily: "Nats-Regular",
                          color: "#ffffff",
                          paddingTop: "5px",
                        }}
                      >
                        Bridge Dashboard
                      </h1>
                    )}
                  </Row>
                </Link>
              </Col>
              <Col xs={8}>
                <Route exact path="/dashboard">
                  {isMobile ? (
                    <ProfileAreaMobile
                      handlelogout={this.handleLogout}
                      username={this.state.username}
                    ></ProfileAreaMobile>
                  ) : (
                    <ProfileArea
                      handlelogout={() => {
                        this.handleLogout();
                      }}
                      username={this.state.username}
                    ></ProfileArea>
                  )}
                </Route>
              </Col>
            </Row>
          </Navbar.Brand>
        </Container>
      </Navbar>
    );
  }
}

export default withAuth0(Header);
