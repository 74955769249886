import "./App.css";

import { Col, Container, Row } from "react-bootstrap";
import React, { Component } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";

import { Config } from "./config";

import Dashboard from "./pages/dashboard";
import Header from "./components/header";
import Login from "./pages/login";

import NetworkManager from "./lib/networkManager";

import LoginDeviceCode from "./pages/loginDeviceCode";

const BackgroundGradient = () => {
  return (
    <div>
      <div
        className={"shape-1 "}
        style={{ height: "82vh", width: "100%", marginTop: "70px" }}
      ></div>
      <div
        className={"shape-2 "}
        style={{ height: "79vh", width: "100%", marginTop: "70px" }}
      ></div>
      <div
        className={"shape"}
        style={{ height: "75vh", width: "100%", marginTop: "70px" }}
      ></div>
    </div>
  );
};

class App extends Component<WithAuth0Props> {
  networkManager: NetworkManager = NetworkManager.getInstance();

  state = {
    versionWasLogged: false,
  };

  logAndRenderVersionTag() {
    if (!this.state.versionWasLogged) {
      console.log(
        `[Bridge Dashboard]\nClient name: ${Config.clientName}\nEnvironment: ${Config.environment}\nVersion: ${Config.versionTag}`
      );
      this.setState({ versionWasLogged: true });
    }

    // Only render version tag on non-production dashboards
    if (Config.environment != "prod")
      return (
        <Row
          className="fixed-bottom"
          style={{ justifyContent: "center", backgroundColor: "#292929" }}
        >
          <h6 style={{ fontFamily: "Nats-Regular", color: "#ffffff" }}>
            {Config.clientName} | {Config.environment} | {Config.versionTag}
          </h6>
        </Row>
      );
    else return null;
  }

  render() {
    const { isAuthenticated } = this.props.auth0;

    return (
      <Router>
        <div style={{ justifyContent: "center" }}>
          <BackgroundGradient />
          <Container>
            <Row>
              <Header />
            </Row>
            <Row
              xs={12}
              md={8}
              style={{
                paddingTop: "170px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Col xs={12} md={12} style={{ justifyContent: "center" }}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => {
                      return isAuthenticated ? (
                        <Redirect push to="/dashboard" />
                      ) : (
                        <Login />
                      );
                    }}
                  />
                  <Route path={Config.loginRoute} component={Login} />
                  <Route path={Config.dashboardRoute} component={Dashboard} />
                  <Route
                    path={Config.loginDeviceCodeRoute}
                    component={LoginDeviceCode}
                  />
                </Switch>
              </Col>
              {this.logAndRenderVersionTag()}
            </Row>
          </Container>
        </div>
      </Router>
    );
  }
}

export default withAuth0(App);
